import React from 'react';
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    children: 'https://landing.ahromm.com/logo.png',
  },
  Menu: {
    className: 'header0-menu',
    children: [
      {
        name: 'item0',
        className: 'header0-item',
        children: {
          href: '/pricing',
          children: [
            {
              children: (
                <span>
                  <p>Pricing</p>
                  <p>
                    <br />
                  </p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item~lyex2zienr',
        className: 'header0-item',
        children: {
          href: '/tutorials',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>Tutorials</p>
                              <p>
                                <br />
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
      {
        name: 'item~lyk1xc3bv3g',
        className: 'header0-item',
        children: {
          href: 'https://ahromm.com',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Home</p>
                        <p>
                          <br />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
        subItem: null,
      },
    ],
  },
  mobileMenu: { className: 'header0-mobile-menu' },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Ahromm Pricing&nbsp;</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <h2>Compare us with&nbsp;</h2>
            </span>
          </span>
        ),
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <b>Google Analytics</b>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <h3>
                            <b>
                              <br />
                            </b>
                          </h3>
                          <h2>
                            Free but Limited,Robust Analytics, Lacks Automation
                          </h2>
                          <b>GA4</b> is a popular analytics tool that provides
                          insights into user behavior and interactions on
                          websites and apps. However, it primarily focuses on
                          event-based data rather than session-based data. While
                          it offers a wealth of information, it mainly provides
                          page visit tracking and doesn’t offer marketing
                          automation or content automation. In contrast, Ahromm
                          not only provides real-time data analytics and user
                          journey analysis but also offers marketing automation
                          and content automation. This makes <b>Ahromm </b>a
                          more comprehensive solution for startups and SMBs.<br />How
                          ever we recommend you to have GA4 beside Ahromm.<br />
                          <br />
                          <br />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'tablet' },
          text: {
            className: 'content7-tag-name',
            children: (
              <span>
                <span>
                  <span>
                    <p>MixPanel</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <h3>
                              Complex Setup, No Automation<br />
                              <p>
                                <br />Mixpanel provides data analytics and user
                                journey analysis, but it doesn’t offer marketing
                                automation or content automation. Moreover,
                                setting up Mixpanel can be complex as it
                                requires defining events, users, and properties<br />
                              </p>
                              <p>
                                While implementing can indeed pose challenges,
                                on Upwork, there are many experts specialized in
                                this area who can assist. In contrast to
                                Mixpanel's restriction to an average of 3,300
                                daily user visits with its 20 million free
                                events, Ahromm offers cost-effective packages
                                from the outset and facilitates implementation
                                with minimal technical expertise required.<br />
                              </p>
                              <br />
                            </h3>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <p>Posthog</p>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'laptop' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <h3>Reasonable Pricing , No Automation</h3>
                      <br />PostHog is an open-source product analytics suite
                      that provides developers with a comprehensive set of tools
                      to understand user behavior, conduct A/B testing, and
                      improve their products. However, it’s important to note
                      that PostHog does not offer marketing and content
                      automation features. This means that while it excels in
                      providing insights into product usage and user behavior,
                      it may not fulfill all the needs of a full-fledged
                      marketing automation platform. Additionally, some users
                      have reported that the implementation of PostHog can be
                      challenging. This could be due to its extensive features
                      and the technical knowledge required to self-host and
                      configure the platform.&nbsp;<br />
                      <br />
                      <span>
                        <h3 />
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
          },
        },
      },
      {
        name: 'block~lyiwupyjrk',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <span>
                  <span>
                    <p>Heap</p>
                  </span>
                </span>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'laptop' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <h3>
                    <br />
                  </h3>
                  <p>
                    <h3>
                      Expensive Pricing, Moderate Implementation Complexity
                    </h3>
                    <br />Heap offers data analytics and user journey analysis,
                    but it lacks marketing automation and content automation. It
                    focuses on understanding user behavior and optimizing user
                    experiences. However, it doesn’t offer an auto-tracking
                    feature. Ahromm’s comprehensive feature set, including these
                    features, could make it a better fit for startups and SMBs<br />
                  </p>
                  <h3>
                    <br />
                  </h3>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
          },
        },
      },
      {
        name: 'block~lyiwv3x7iid',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <span>
                  <span>
                    <p>Amplitude</p>
                  </span>
                </span>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'laptop' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <h3>
                  Expensive, Complex Setup, Lacks Automation<br />
                  <br />
                </h3>Amplitude provides product analytics and user journey
                analysis. However, it doesn’t provide marketing automation or
                content automation. Moreover, it doesn’t have an auto-tracking
                feature, which means that setting up event tracking might
                require some technical knowledge. On the other hand, Ahromm
                provides an easy implementation system called Auto Tracking,
                making it more user-friendly and accessible for non-technical
                users. The pricing for Amplitude starts at $49 per month.<span>
                  <h3 />
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
          },
        },
      },
      {
        name: 'block~lyixxabo8e',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>Matomo</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'laptop' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <h3>Expensive, User Behavior Analysis, Lacks Automation</h3>
                    <p>
                      While Matomo offers data analytics and user journey
                      analysis, it doesn’t provide marketing automation or
                      content automation. It focuses on understanding user
                      behavior and optimizing content. However, it doesn’t offer
                      an auto-tracking feature. Ahromm’s comprehensive feature
                      set, including these features, could make it a more
                      suitable choice for startups and SMBs. The pricing for
                      100K starts from $42.<br />
                    </p>
                    <h3>
                      <br />
                    </h3>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              'https://zos.alipayobjects.com/rmsportal/xBrUaDROgtFBRRL.png',
          },
        },
      },
    ],
  },
};
export const Pricing20DataSource = {
  wrapper: { className: 'home-page-wrapper pricing2-wrapper' },
  page: { className: 'home-page pricing2' },
  OverPack: { playScale: 0.3, className: 'pricing2-content-wrapper' },
  titleWrapper: {
    className: 'pricing2-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Pricing Packages</p>
            </span>
          </span>
        ),
        className: 'pricing2-title-h1',
      },
    ],
  },
  Table: {
    name: 'tabsTitle',
    size: 'default',
    className: 'pricing2-table',
    columns: {
      children: [
        {
          dataIndex: 'name',
          key: 'name',
          name: 'empty',
          childWrapper: {
            children: [
              { name: 'name', children: ' ' },
              { name: 'content', children: ' ' },
            ],
          },
        },
        {
          dataIndex: 'free',
          key: 'free',
          name: 'free',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <span>
                      <span>
                        <p>Essentia</p>
                      </span>
                    </span>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <span>
                      <p>$32 / 250K Tokens</p>
                    </span>
                  </span>
                ),
              },
              {
                name: 'button',
                children: {
                  href: 'https://app.ahromm.com/account/user',
                  children: (
                    <span>
                      <p>Start Now</p>
                    </span>
                  ),
                  type: 'primary',
                },
              },
            ],
          },
        },
        {
          dataIndex: 'basic',
          key: 'basic',
          name: 'basic',
          childWrapper: {
            className: 'pricing2-table-name-block',
            children: [
              {
                name: 'name',
                className: 'pricing2-table-name',
                children: (
                  <span>
                    <span>
                      <span>
                        <p>Pay as You Go</p>
                      </span>
                    </span>
                  </span>
                ),
              },
              {
                name: 'content',
                className: 'pricing2-table-money',
                children: (
                  <span>
                    <span>
                      <span>
                        <p>$0.5 / 10K Tokens</p>
                      </span>
                    </span>
                  </span>
                ),
              },
              {
                name: 'button',
                children: {
                  href: 'https://app.ahromm.com/account/user',
                  children: (
                    <span>
                      <p>Start Now</p>
                    </span>
                  ),
                  type: 'primary',
                },
              },
            ],
          },
        },
      ],
    },
    dataSource: {
      children: [
        {
          name: 'list0',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <p>Event Store</p>
                  </span>
                </span>
              ),
            },
            {
              children: (
                <span>
                  <span>
                    <p>10k Tokens = 10,000 Events</p>
                  </span>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>$0.5 / 10K Tokens</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list1',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <p>Marketing Automation</p>
                  </span>
                </span>
              ),
            },
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>20 Tokens /Active User </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              children: (
                <span>
                  <p>20 Tokens /Active User </p>
                </span>
              ),
              name: 'content1',
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list2',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <p>Content Automation</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>10K Tokens / Automation/ </p>
                    <p>Unlimited Users</p>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                        <p>10K Tokens / Automation/ </p>
                        <p>Unlimited Users</p>
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list3',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <span>
                    <p>Data Visualization</p>
                  </span>
                </span>
              ),
            },
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>Unlimited</p>
                    </span>
                  </span>
                </span>
              ),
              name: 'content0',
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>Unlimited</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list4',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name',
              children: (
                <span>
                  <p>Auto Tracking Configurations</p>
                </span>
              ),
            },
            {
              name: 'content0',
              children: (
                <span>
                  <p>Unlimited</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
            {
              name: 'content1',
              children: (
                <span>
                  <p>Unlimited</p>
                </span>
              ),
              className: 'pricing2-table-content',
            },
          ],
        },
        {
          name: 'list5',
          children: [
            {
              className: 'pricing2-table-content-name',
              name: 'name~lyexmieczub',
              children: (
                <span>
                  <p>
                    <br />
                  </p>
                </span>
              ),
            },
          ],
        },
      ],
    },
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper lyexsu7e8rg-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <h2 style={{color:'white'}}>What Does Token Mean ?</h2>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1 lyk363rfz7u-editor_css',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>$0.5 / 10K Tokens</p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <span>
                <p>
                  In Ahromm, event tracking costs 1 token per event occurrence,
                  with 10,000 tokens available for $0.50.&nbsp;<br />
                </p>
              </span>
            </span>
          </span>
        ),
        className: 'title-content lyey32m2jd-editor_css',
      },
      {
        name: 'content~lyey5roh1e',
        className: 'lyey5sfr3ju-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    In Ahromm, each marketing automation scenario costs 20
                    tokens per active user. For example, running 2 scenarios for
                    1,000 users would require 40,000 tokens monthly.&nbsp;
                  </p>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>©2024 Ahromm&nbsp;All Rights Reserved</span>
      </span>
    ),
  },
};
