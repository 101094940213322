import React from 'react';
export const Banner20DataSource = {
  wrapper: { className: 'banner2' },
  BannerAnim: {
    children: [
      {
        name: 'elem0',
        BannerElement: { className: 'banner-user-elem' },
        page: { className: 'home-page banner2-page' },
        textWrapper: { className: 'banner2-text-wrapper' },
        bg: { className: 'bg bg0 lyx5y6okua-editor_css' },
        title: {
          className: 'banner2-title lyx5wx11c4n-editor_css',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <p>Ahromm Tutorials</p>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'banner2-content',
          children: (
            <span>
              <p>Implementation Guide</p>
            </span>
          ),
        },
        button: {
          className: 'banner2-button',
          children: (
            <span>
              <p>Try Ahromm</p>
            </span>
          ),
          href: '/pricing',
          type: 'primary',
        },
      },
    ],
  },
};
export const Feature00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Tutorials / Ahromm Guide</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://public1data.blob.core.windows.net/landing/Icons/implementation.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <p>Implementation / Data Analysis</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    Implementation Tutorial Video<br />Product Management
                    Section Guide
                  </p>
                </span>
              ),
            },
            {
              name: 'button~lyx58xp4qp',
              className: '',
              children: {
                children: (
                  <span>
                    <span>
                      <p>Start Learning</p>
                    </span>
                  </span>
                ),
                href: '/tutorial/implementation-product-analysis',
                type: 'primary',
                className: 'lyx5r94cmo-editor_css',
              },
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://public1data.blob.core.windows.net/landing/Icons/marketing.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Marketing<br />&nbsp;Automation&nbsp;
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      Marketing Automation <br />Service Guide
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'button~lyx56pg4izg',
              className: '',
              children: {
                children: (
                  <span>
                    <span>
                      <p>Start Learning</p>
                    </span>
                  </span>
                ),
                href: '/tutorial/marketing-automation',
                type: 'primary',
                className: 'lyx5rl85oz-editor_css',
              },
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://public1data.blob.core.windows.net/landing/Icons/conetnt.png',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>
                      Content <br />Automation&nbsp;
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      Content Automation <br />Service Guide
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'button~lyx57ynnovr',
              className: '',
              children: {
                children: (
                  <span>
                    <span>
                      <p>Start Learning</p>
                    </span>
                  </span>
                ),
                href: '/tutorial/content-automation',
                type: 'primary',
                className: 'lyx5roei7sn-editor_css',
              },
            },
          ],
        },
      },
    ],
  },
};
export const Content40DataSource = {
  wrapper: { className: 'home-page-wrapper content4-wrapper' },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title~lyx5tejvgc',
        className: '',
        children: (
          <span>
            <span>
              <span>
                <p>Implementation/Product Analysis Guide</p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video:
        'https://public1data.blob.core.windows.net/videos/docs-%20intro%20and%20implementation.mp4',
      image: 'https://zos.alipayobjects.com/rmsportal/HZgzhugQZkqUwBVeNyfz.jpg',
    },
  },
};
