import React from 'react';
import { MediaPlayer, MediaProvider } from '@vidstack/react';
import { defaultLayoutIcons, DefaultVideoLayout } from '@vidstack/react/player/layouts/default';

class VideoPlayer extends React.Component {
    render() {
        return (
            <MediaPlayer playsInline preload='auto' title="Ahromm Tutorial" src="https://content.ahromm.com/videos/docs-%20intro%20and%20implementation.mp4">
                <MediaProvider />
                <DefaultVideoLayout  icons={defaultLayoutIcons} />
            </MediaPlayer>
        );
    }
}

export default VideoPlayer;