import {  Route, Routes } from 'react-router-dom';
import Index from './Home/index'
import Tutorials from './Tutorials/index'
const AppRoutes = () => {
  return (
    <Routes >
      <Route  path={'pricing'} Component={Index} />
      <Route  path={'tutorials'} Component={Tutorials} />
      <Route path="*" element={<Index />} />
    {/* Add more routes as needed */}
  </Routes>
  );
};

export default AppRoutes;
